<template>
  <v-dialog
    v-model="createAchievementModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar conquista
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mx-5 mt-5"
          @submit.prevent="createAchievement()"
        >
          <v-text-field
            v-model="achievement.title"
            :rules="[rules.required]"
            outlined
            required
            label="Nome da conquista *"
          />

          <v-textarea
            v-model="achievement.description"
            :rules="[rules.required]"
            outlined
            required
            label="Descrição da conquista *"
            hint="Ex: Acertou o resultado de 5 partidas na rodada"
            rows="3"
          />

          <v-select
            v-model="achievement.rarity"
            outlined
            label="Raridade da conquista"
            :items="rarities"
            :rules="[rules.required]"
          />

          <image-uploader
            title="Clique aqui para enviar a imagem da conquista"
            subtitle="Tamanho recomendado: 150 por 150 pixels"
            :saving="saving"
            :fullWidth="true"
            previewWidth="150px"
            previewHeight="150px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createAchievement()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    ImageUploader: () => import('@/components/ImageUploader.vue')
  },
  data () {
    return {
      saving: false,
      valid: true,
      image: null,
      achievement: {
        title: '',
        description: '',
        rarity: '',
        imageType: ''
      },
      rarities: [
        'Comum',
        'Raro',
        'Épico',
        'Legendário'
      ],
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    createAchievementModal: {
      get () {
        return this.$store.state.createAchievementModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createAchievementModal',
          value: val
        })
      }
    }
  },
  watch: {
    createAchievementModal (val) {
      if (!val) {
        this.achievement = {
          title: '',
          description: '',
          rarity: '',
          imageType: ''
        }
      }
    }
  },
  methods: {
    createAchievement () {
      if (this.$refs.form.validate()) {
        if (!this.achievement.imageType) {
          this.$toast.error('É necessário enviar a imagem da conquista.')
          return
        }
        this.saving = true
        this.$http.post('/achievements', this.achievement)
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    success () {
      this.saving = false
      this.createAchievementModal = false
      this.$emit('created')
      this.$toast.success('Cadastro realizado com sucesso.')
    },
    error () {
      this.saving = false
      this.createAchievementModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.achievement.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.achievement.imageType = undefined
      this.image = null
    }
  }
}
</script>
